import React from 'react';
import { Box, Select, FormLayout, TextField, Button, Icon, LegacyStack, ButtonGroup, Text } from "@shopify/polaris";
import { UploadIcon } from "@shopify/polaris-icons";
import ImagesSelector from "./ImagesSelector"
import CardDivider from '../../../../components/cardDivider';
import update from "immutability-helper"
const imageFitOptions = [
  { label: "Fill", value: "fill" },
  { label: "Contain", value: "contain" },
  { label: "Cover", value: "cover" },
]
const alignmentOptions = [
  { label: "Left", value: "left" },
  { label: "Center", value: "center" },
  { label: "Right", value: "right" },
]
const borderRadiusOptions = [
  { label: "None", value: "none" },
  { label: "Base", value: "base" },
  { label: "Small", value: "small" },
  { label: "Large", value: "large" },
  { label: "Fully rounded", value: "fullyRounded" },
]
export default function ImageContent({ token, shop, host, contentEdit, state, setState }) {
  const onImageChange = (img) => {
    setState(
      update(state, {contents: {
        [contentEdit]: { img: { $set: img.image.originalSrc } },
      }})
    )
  }
  const content = state.contents[contentEdit]
  return (
    <FormLayout>
      <h6>
        <Text  variant="bodyMd" as="span" fontWeight="semibold">Image</Text>
      </h6>
      <div
       style ={{
        borderStyle:"dashed",
        minHeight:"165px",
        minWidth:"500px",
        background:"bg-interactive-disabled",
        borderColor:"border-strong",
        borderWidth:"base",
        borderRadius:"large",
        }}
      >
        {content.img ? (
          <img
            src={content.img}
            alt="Selected image"
            style={{ maxWidth: "500px" }}
          />
        ) : (
          <div style={{ padding: "35px 0" }}>
            <LegacyStack gap="10px" vertical alignment="center">
              <Icon source={UploadIcon} tone="subdued" />
              <ImagesSelector token={token} shop={shop} host={host} onChange={onImageChange} type="IMAGE" />
            </LegacyStack>
          </div>
        )}
      </div>
      {content.img && (
        <ButtonGroup variant="segmented" fullWidth>
          <ImagesSelector token={token} shop={shop} host={host} onChange={onImageChange} type="IMAGE" />
          <Button onClick={() => onImageChange(null)}>Remove</Button>
        </ButtonGroup>
      )}
      <CardDivider/>
      <h6>
        <Text  variant="bodyMd" as="span" fontWeight="semibold">Layout</Text>
      </h6>
      <Select
        label="Border radius"
        options={borderRadiusOptions}
        value={content.borderRadius}
        onChange={(value) => {
          setState(
            update(state, {contents: {
              [contentEdit]: { borderRadius: { $set: value } },
            }})
          )
        }}
      />
      <Select
        label="Image fit"
        options={imageFitOptions}
        value={content.fit}
        onChange={(value) => {
          setState(
            update(state, {contents: { [contentEdit]: { fit: { $set: value } } }})
          )
        }}
      />
      <Select
        label="Alignment"
        options={alignmentOptions}
        value={content.alignment}
        onChange={(value) => {
          setState(
            update(state, {contents: { [contentEdit]: { alignment: { $set: value } } }})
          )
        }}
      />
    </FormLayout>
  );
}
